<template>
    <div class="view-wrap" v-loading="loading">
        <div class="view">
            <el-table
                :data="tableData"
                style="width: 100%;margin-bottom: 20px;"
                :highlight-current-row="true" 
                :header-cell-style="{'background':'#F5F6F8','color': '#333333'}"
                row-key="id"
                border
                default-expand-all
                :tree-props="{children: 'list', hasChildren: 'hasChildren'}">
                <el-table-column
                prop="name"
                label="商品类别名称">
                </el-table-column>
            </el-table>
            <el-pagination
            class="pagination"    
            @size-change="changePageSize"
            @current-change="changeCurrentPage"
            :current-page="searcForm.pageNum"
            :page-size="searcForm.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
        </div>
    </div>
</template>
<script>
import { GetProductTypeList } from '@/api/productType'
export default {
    data(){
        return{
            loading: false,
            searcForm:{
                pageNum: 1,
                pageSize: 10
            },
            total:0,
            tableData: [],
            tableItem:[{prop:'name',label:'商品类别名称', type:'default'}]
        }
    },
    created(){
        this.getData()
    },
    methods:{
        getData(){
            this.loading = true
            GetProductTypeList(this.searcForm).then(res => {
                if(res.code === 1) {
                    const {records,current,total} = res.data
                    this.tableData = records
                    this.total = total
                    this.searcForm.pageNum = current
                } else {
                    this.$message.error(res.msg)
                }
                this.loading = false
            })
        },
        // 新增
        UpDate(){},
        // 修改
        EditData(){},
        // 删除
        DeleteData(){},
        changePageSize(val){
            this.searcForm.pageSize = val
            this.searcForm.pageNum = 1
            this.getData()
        },
        changeCurrentPage(val) {
            this.searcForm.pageNum = val
            this.getData()
        }
    }
}
</script>
<style lang="scss" scoped>
.pagination{
    width: 100%;
    text-align: center;
    margin-top: 30px;
}
</style>